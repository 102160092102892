import React from 'react';
import { Container, Grid, Typography, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from "../Assist/home_page_logo.png";
import '../StyleSheet/footer.css';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <Container maxWidth="lg" className="footerContainer">
                    {/* Logo and Name Section */}
                    <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                        <Grid item xs={12} md={4} className="footerSection">
                            <div className="footerLogoContainer">
                                <img src={Logo} alt="Company Logo" style={{width:'120px'}} />
                                <Typography variant="h6" style={{color:'white'}}>
                                    Semicolon Code
                                </Typography>
                                <Typography variant="body2" style={{color:'white'}}>
                                    Software Solutions
                                </Typography>
                            </div>
                        </Grid>

                        {/* Contact Information Section */}
                        <Grid item xs={12} md={4} style={{color:'white'}}>
                            <Typography variant="subtitle1" className="footerHeading">
                                Contact Us
                            </Typography>
                            <Typography variant="body2" className="footerContactInfo">
                                Email: semicolon.code.service@gmail.com
                            </Typography>
                            <Typography variant="body2" className="footerContactInfo">
                                Phone: +962 79 545 9803
                            </Typography>
                        </Grid>

                        {/* Social Media Icons Section */}
                        <Grid item xs={12} md={4} style={{color:'white'}}>
                            <Typography variant="subtitle1" className="footerHeading">
                                Follow Us
                            </Typography>
                            <div className="socialIcons">
                                <IconButton style={{color:'white'}} aria-label="LinkedIn" href="https://www.linkedin.com/company/semicolon-code" target="_blank" className="iconButton">
                                    <LinkedInIcon fontSize="large" />
                                </IconButton>
                                <IconButton style={{color:'white'}} aria-label="Email" href="mailto:semicolon.code.service@gmail.com" className="iconButton">
                                    <EmailIcon fontSize="large" />
                                </IconButton>
                                <IconButton style={{color:'white'}} aria-label="Instagram" href="https://www.instagram.com/semicolon.code/" target="_blank" className="iconButton">
                                    <InstagramIcon fontSize="large" />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{color:'white'}}>
                        <Typography variant="body2" style={{color:'white'}} align="center">
                            © {new Date().getFullYear()} Semicolon Code. All rights reserved.
                        </Typography>
                    </div>
                </Container>
            </footer>
        );
    }
}

export default Footer;
