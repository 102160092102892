import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HeaderBK from '../Assist/header_background_business.jpg';
import HomePageLogo from '../Assist/home_page_logo.png';
import Counter from './Counter';
import '../StyleSheet/header.css';
const Header = ({ goToSection, infoRef, contactRef }) => {
  return (
    <div
      // style={{
      //   height: '100vh',
      //   backgroundImage: `url(${HeaderBK})`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      //   display: 'flex',
      //   alignItems: 'center',
      //   justifyContent: 'space-around',
      //   padding: '0 5%',
      //   position: 'relative',
      // }}
      className='header-container'
    >
      {/* Logo in the Top Right */}
      <Box
        component="img"
        src={HomePageLogo}
        alt="Home Page Logo"
        sx={{
          position: 'absolute',
          top: '40px',
          right: '40px',
          width: '160px',
          height: 'auto',
        }}
      />

      {/* Header Content */}
      <div 
      // style={{
      //   backgroundColor: 'rgba(255, 255, 255, 0.8)',
      //   padding: '20px 40px',
      //   maxWidth: '400px',
      //   borderRadius: '8px',
      // }}
      className='header-content'>
        <Typography
          variant="body2"
          style={{
            fontSize: '1.3rem',
            color: '#333',
            marginBottom: '8px',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          Your Trusted Partner in Software Innovation
        </Typography>
        <Typography
          variant="h4"
          style={{
            fontSize: '2rem',
            fontWeight: 'bold',
            color: '#1f1f1f',
            margin: 0,
            fontFamily: 'Arial Black, sans-serif',
            textTransform: 'uppercase',
          }}
        >
          Building Software Solutions for a Digital Future
        </Typography>
        
        {/* About Us Button */}
        <Button
          variant="contained"
          onClick={() => goToSection(infoRef)} // Scroll to InformationCard
          style={{
            marginTop: '20px',
            backgroundColor: '#1c1c69',
            color: '#fff',
            padding: '10px 20px',
            fontWeight: 'bold',
            fontSize: '1rem',
            textTransform: 'uppercase',
            borderRadius: '20px',
          }}
        >
          About Us
        </Button>

        {/* Contact Us Button */}
        <Button
          variant="contained"
          onClick={() => goToSection(contactRef)} // Scroll to ContactUs
          style={{
            marginTop: '20px',
            backgroundColor: 'green',
            color: '#fff',
            padding: '10px 20px',
            fontWeight: 'bold',
            marginLeft: 12,
            fontSize: '1rem',
            textTransform: 'uppercase',
            borderRadius: '20px',
          }}
        >
          Contact Us
        </Button>
      </div>

      {/* Counter Component */}
      <Counter />
    </div>
  );
};

export default Header;
