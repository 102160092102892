import React, { useRef } from 'react';
import './StyleSheet/App.css';
import Header from './Components/Header';
import InformationCard from './Components/InformationCards';
import ContactUs from './Components/ContactUs';
import Footer from './Components/Footer';
import InstagramWidget from './Components/InstagramWidget';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
const App = () => {
  const infoRef = useRef(null);
  const contactRef = useRef(null);
  
  const goToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };
 



  return (
    <div>
      <Header goToSection={goToSection} infoRef={infoRef} contactRef={contactRef} />
      
      <div style={{ backgroundColor: '#ffffff', width: '95%', borderRadius: '13px', margin: 'auto', padding: '10px', marginTop: '7%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', paddingBottom: '60px' }}>
        <div ref={infoRef}>
          <InformationCard />
        </div>
      </div>

      {/* Instagram Widget */}
      {/* <InstagramWidget /> */}
     
      <AnimationOnScroll animateIn="animate__bounceIn" style={{ width: '97%', borderRadius: '13px', margin: 'auto', padding: '1px', marginTop: '2%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
        <div ref={contactRef} style={{ textAlign: 'center', height: '100%', margin: 'auto' }}>
          <ContactUs />
        </div>
      </AnimationOnScroll>

      <Footer />
    </div>
  );
};

export default App;
