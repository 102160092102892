import React from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import emailjs from '@emailjs/browser';
import '../StyleSheet/contactUs.css';

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            message: '', 
            email: '', 
            customerName: '',
            showSuccessAlert: false,
            showErrorDialog: false 
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseErrorDialog = this.handleCloseErrorDialog.bind(this);
    }
  
    handleCloseErrorDialog() {
        this.setState({ showErrorDialog: false });
    }

    render() {
        return (
            <Grid container spacing={2} className="contactUsContainer">
                <Grid item xs={12} md={10} lg={8} className="contactUsCard">
                    <div className="contactHeader">
                        <h2>Contact Us</h2>
                    </div>
                    <p className="contactDescription">
                        To complete your project with the best contract, contact us directly. 
                        We will reply within 5 minutes. Send us your message and include your 
                        WhatsApp number or make sure to write your email. We’ll get back to you 
                        via email or WhatsApp.
                    </p>
                    <div className="contactFields">
                        <TextField
                            required
                            className="contactInput"
                            label="Full Name"
                            variant="outlined"
                            onChange={(event) => this.setState({ customerName: event.target.value })}
                        />
                        <TextField
                            required
                            className="contactInput"
                            label="Email"
                            variant="outlined"
                            onChange={(event) => this.setState({ email: event.target.value })}
                        />
                        <TextField
                            required
                            className="contactMessage"
                            label="What can we help you with?"
                            placeholder="Tell us about your project, idea, or dream. We are here to support you!"
                            multiline
                            rows={4}
                            variant="outlined"
                            onChange={(event) => this.setState({ message: event.target.value })}
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="contactButton"
                        onClick={this.handleSubmit}
                    >
                        Send Message
                    </Button>

                    {/* Success Alert */}
                    {this.state.showSuccessAlert && alert("Email successfully sent!")}

                    {/* Error Dialog */}
                    <Dialog
                        open={this.state.showErrorDialog}
                        onClose={this.handleCloseErrorDialog}
                    >
                        <DialogTitle>Email Not Sent</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Unfortunately, we couldn't send your email. Would you like to chat with us on WhatsApp instead?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseErrorDialog} color="primary">
                                Cancel
                            </Button>
                            <Button 
                                color="primary" 
                                href="https://wa.me/962795459803" 
                                target="_blank"
                            >
                                Go to WhatsApp
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        );
    }

    handleSubmit(event) {
        const templateId = 'template_s3gof85';
        this.sendFeedback(templateId, {
            'message_html': this.state.message,
            'from_name': this.state.customerName,
            'reply_to': this.state.email,
        });
    }

    sendFeedback(templateId, variables) {
        emailjs.send("service_owh8phx", templateId, {
            senderName: variables.from_name,
            message: variables.message_html,
            reply_to: variables.reply_to,
        }, "p4kMkteFX2E4NAkNI")
        .then(res => {
            console.log('Email successfully sent!');
            this.setState({ 
                message: '', 
                showSuccessAlert: true, 
                showErrorDialog: false 
            });
            // Hide success alert after 2 seconds
            setTimeout(() => this.setState({ showSuccessAlert: false }), 2000);
        })
        .catch(err => {
            console.error('Error sending email:', err);
            this.setState({ showErrorDialog: true });
        });
    }
}

export default ContactUs;
