import React, { useRef } from 'react';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import Grid from '@mui/material/Grid';
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
import { MdSupportAgent, MdModelTraining } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi';
import SemicolonLogo from '../Assist/semicolonlogo.jpg';
import '../StyleSheet/informationCards.css';
export default function InformationCards() {
  const ref = useRef();

  return (
    <Grid container className="infoCardsContainer">
      {/* Flippable Card */}
      <Grid item xs={12} md={4} className="flippyCardContainer">
        <Flippy
          flipOnHover={true}
          flipOnClick={true}
          flipDirection="horizontal"
          ref={ref}
          className="flippyCard"
        >
          <FrontSide className="flippyFrontSide">
            <img src={SemicolonLogo} alt="Semicolon Logo" className="flippyImage" />
            <h3 className="flippyTitle">Why Choose Semicolon Code?</h3>
          </FrontSide>
          <BackSide className="flippyBackSide">
            <p className="flippyBackText" style={{color:'#ffffff'}}>
              We provide reliable, long-term contracts for maintenance, support, and more.
            </p>
          </BackSide>
        </Flippy>
      </Grid>

      {/* Points Section */}
      <Grid item xs={12} md={8} className="pointsSection">
        <div className="pointsRow">
          <div className="pointItem">
            <HiOutlineClipboardDocumentCheck className="pointIcon" />
            <h4 className="pointTitle">Comprehensive Documentation</h4>
            <p className="pointDescription">
              Get full documentation covering every detail, ensuring transparency and clarity.
            </p>
          </div>
          <div className="pointItem">
            <MdSupportAgent className="pointIcon" />
            <h4 className="pointTitle">24/7 Dedicated Support</h4>
            <p className="pointDescription">
              Our team is here round-the-clock to help you stay on track, always.
            </p>
          </div>
        </div>
        <div className="pointsRow">
          <div className="pointItem">
            <GiReceiveMoney className="pointIcon" />
            <h4 className="pointTitle">Efficient & Cost-Effective</h4>
            <p className="pointDescription">
              High-quality services at competitive prices to maximize your ROI.
            </p>
          </div>
          <div className="pointItem">
            <MdModelTraining className="pointIcon" />
            <h4 className="pointTitle">Tailored Training Programs</h4>
            <p className="pointDescription">
              Customized training to empower your team to utilize all features effectively.
            </p>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
