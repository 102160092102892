import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import '../StyleSheet/counter.css';

const Counter = () => {
  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [counter3, setCounter3] = useState(0);
  const [counter4, setCounter4] = useState(0);

  

  useEffect(() => {
    const incrementCounter = (setCounter, max) => {
      const interval = setInterval(() => {
        setCounter((prev) => {
          if (prev < max) return prev + 1;
          clearInterval(interval);
          return max;
        });
      }, 50);
    };

    incrementCounter(setCounter1, 30);
    incrementCounter(setCounter2, 16);
    incrementCounter(setCounter3, 100);
    incrementCounter(setCounter4, 111);
    
  }, []);

  return (
    <Grid container spacing={2} className="counter-container" style={{ maxWidth: '500px' }}>
      <Grid item xs={6} sm={3} className="counterItem">
        <div className="counterCircle">
          <h2>{counter1}%</h2>
        </div>
        <p style={{color:'white'}}>More Value, Less Cost</p>
        <p style={{color:'white'}}>Enjoy 30% Savings with Our Expert Team</p>

      </Grid>

      <Grid item xs={6} sm={3} className="counterItem">
        <div className="counterCircle">
          <h2>{counter2}</h2>
        </div>
        <p style={{color:'white'}}>Your Industry, Our Expertise</p>
        <p style={{color:'white'}}>Serving 16+ Sectors with Proven Results</p>
      </Grid>
      <Grid item xs={6} sm={3} className="counterItem">
        <div className="counterCircle">
          <h2>{counter3}%</h2>
        </div>
        <p style={{color:'white'}}>Dependable Maintenance Contracts</p>
        <p style={{color:'white'}}>Tailored for Your Peace of Mind</p>
      </Grid>
      
    </Grid>
  );
};

export default Counter;
